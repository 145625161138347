@font-face {
    font-family: "Handy Sans";
    src: url('../fonts/handy-sans.otf') format('opentype'), url("../fonts/handy-sans.woff2") format("woff2"), url("../fonts/handy-sans.woff") format("woff"), url("../fonts/handy-sans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


%primary-font {
    font-family: "Handy Sans";
    text-transform: uppercase;
}

%main-font {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: $base-font-size;
    line-height: $base-font-size + 5;
    text-transform: none;
}

h1,
.primary-font {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h2,
h3,
.secondary-font {
    @extend %primary-font;
}

.secondary-text-colour {
    color: $secondary-color;
}

h4,
h5,
body,
.main-font {
    @extend %main-font;
}

// text colour classes
.main-text-colour {
    color: $body-font-color;
}

.green-text-colour {
    color: $green;
    &.green-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $green;
            border-color: $green;
        }
    }
}

.dark-blue-text-colour {
    color: $dark-blue;
    &.dark-blue-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $dark-blue;
            border-color: $dark-blue;
        }
    }
}

.pink-text-colour {
    color: $pink;
    &.pink-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $pink;
            border-color: $pink;
        }
    }
}

.orange-text-colour {
    color: $orange;
    &.orange-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $orange;
            border-color: $orange;
        }
    }
}

.yellow-text-colour {
    color: $yellow;
    &.yellow-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $yellow;
            border-color: $yellow; 
        }
    }
}

.blue-text-colour {
    color: $blue;
    &.blue-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
}

.red-text-colour {
    color: $red;
    &.red-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $red;
            border-color: $red;
        }
    }
}

.pale-orange-text-colour {
    color: $pale-orange;
    &.pale-orange-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $pale-orange;
            border-color: $pale-orange;
        }
    }
}

.purple-text-colour {
    color: $purple;
    &.purple-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $purple;
            border-color: $purple;
        }
    }
}

.light-purple-text-colour {
    color: $light-purple;
    &.light-purple-bordered {
        border: 3px solid transparent;
        border-radius: 6px;
        &:hover {
            color: $light-purple;
            border-color: $light-purple;
        }
    }
}

h1 {
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 16px;
}

h2 {
    font-size: $h2-font-size;
    line-height: $h2-font-size;
    margin-bottom: 8px;
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size;
    margin-bottom: 8px;
    margin-top: 16px;
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 8px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 8px;
}

blockquote {
    font-size: 30px;
    line-height: 48px;
    @extend %primary-font;
    cite {
        font-style: normal;
        text-transform: none;
        margin-top: 16px;
        display: block;
        font-size: 18px;
        line-height: 20px;
    }
}

.pagination {
    li {
        a {
            color: $red;
        }
    }
}

.separator {
    padding-top: 32px;
    border-top: 3px solid $section-border-top;
}

.separator-decorative {
    width: 100%;
    &::before {
        content: "";
        height: 80px;
        background: url("../img/separator-decorative.png") center;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        margin: 0 auto;
    }
}

a {
    color: $body-font-color;
    &:hover {
        color: $secondary-color;
        cursor: pointer;
    }
}

p {
    margin-bottom: 1em;
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
}

.primary-button {
    @extend %primary-font;
    @include siteButton($primary-color, $white);
    &:hover {
        background-color: $orange;
        color: $white;
    }
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.secondary-button {
    @extend %primary-font;
    @include siteButton($secondary-color, $white);
    &:hover {
        background-color: $dark-blue;
        color: $white;
    }
    &__character {
        display: inline-block;
        text-align: center;
    }
}

.pink-button {
    @extend %primary-font;
    @include siteButton($pink, $white);
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white, $secondary-color);
}


.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0 0 35px;
    a {
        position: relative;
        @media (max-width: 767px) {
            font-size: 12px;
        }
        &::before{
            content: '';
            background-image: url("../img/left-arrow.svg");
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            display: block;
            top: 50%;
            left: -35px;
            height: 23px;
            width: 23px;
            transform: translateY(-50%);
            filter: invert(39%) sepia(10%) saturate(1906%) hue-rotate(154deg) brightness(92%) contrast(84%);
            @media (max-width: 767px) {
                left: -25px;
                height: 18px;
                width: 18px;
            }
        }
    }
}
