.header {
  background-color: $header-background-color;
  background-size: cover;
  &__logo {
    max-width: none;
    background-image: url('https://supadu-io.imgix.net/harpercollins-petethecat-ssg/petethecat-site/clouds-full.png');
    a[href="https://www.harpercollins.com/childrens/"] {
      right: 7%;
    }
    &__kids-image {
      width: 65px;
    }
  }
}
