.book-card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 10px;
    &__image {
        min-height: 250px;
        display: flex;
        align-items: flex-end;
    }
    &__copy {
        min-height: 50px;
    }

} 

.books-carousel .books-container .book-card .book-image {
    padding: 0;
}